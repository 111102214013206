import React, { useEffect } from 'react'
import Scroll from "../components/scroll"
import SEO from "../components/seo"
import PageTitle from '../components/pageTitle'
import PageImage from '../components/pageImage'
import TextBlock from '../components/textBlock'
import Punchline from '../components/punchline'
import Footer from '../components/footer'
import Smiley from '../components/smiley'
import magnetHover from '../helpers/magnetHover';
import '../styles/pages/volunteer.scss'

const Volunteer = ({ data: { prismic } }) => {

	const data = prismic.allBe_kinds.edges[0].node;

	useEffect(() => {
		magnetHover();
	});

	return (
  	<div className="Volunteer">
			<SEO
				title={data.page_title[0].text}
			/>
			<Scroll onScroll={() => {}}>
				<div className="Volunteer__Container" id="VolunteerHero">
					<div
						className="Volunteer__ImageSticky"
						data-scroll
						data-scroll-sticky
						data-scroll-target="#VolunteerHero"
					>
						<PageImage
							image={data.image}
							imageSharp={data.imageSharp}
							imageHover={data.image_hover}
							imageHoverSharp={data.image_hoverSharp}
						/>
					</div>
					<div className="Volunteer__Right">
						<PageTitle
							top={data.page_title_top_line[0].text}
							bottom={data.page_title_bottom_line[0].text}
							topLineOffset={data.page_title_top_line_offset}
							bottomLineOffset={data.page_title_bottom_line_offset}
						/>
					</div>
					<div className="Volunteer__ImageMobile">
						<PageImage
							image={data.image}
							imageSharp={data.imageSharp}
							imageHover={data.image_hover}
							imageHoverSharp={data.image_hoverSharp}
						/>
					</div>
					<div className="Volunteer__Texts">
						{data.body.map((textBlock, index) => (
							<TextBlock
								title={textBlock.primary.title}
								subtitle={textBlock.primary.subtitle}
								paragraphs={textBlock.primary.paragraphs}
								key={`textblock-${index}`}
								className="Volunteer__TextBlock"
							/>
						))}
					</div>
				</div>
				<Smiley />
				<Punchline />
				<Footer />
			</Scroll>
  	</div>
	)
}

export default Volunteer

export const VolunteerQuery = graphql`
  query VolunteerQuery {
		prismic {
			allBe_kinds {
				edges {
					node {
						page_title
						page_title_top_line_offset
          	page_title_top_line
          	page_title_bottom_line_offset
          	page_title_bottom_line
						image
						imageSharp {
							childImageSharp {
								fluid(maxWidth: 2000) {
									...GatsbyImageSharpFluid
								}
							}
						}
						image_hover
						image_hoverSharp {
							childImageSharp {
								fluid(maxWidth: 2000) {
									...GatsbyImageSharpFluid
								}
							}
						}
						body {
							... on PRISMIC_Be_kindBodyTextblock {
								primary {
									title
									subtitle
									paragraphs
								}
							}
						}
					}
				}
			}
		}
  }
`

